import types from "./types";

const initialState = {
  //reducer send-pacakge
  sender: {},
  receiver: {},
  items: [],
  detail: {
    weight: null,
    width: null,
    height: null,
    length: null,
    qty: null,
    note: "",
  },
  shipping: {
    schedule: "",
    service: "",
    service_type: "",
    drop: false,
    cod: null,
    insurance: false,
    cod_value: 0,
    service_type_id: null,
    insurance_setting: {},
    cod_setting: {},
    courier: {},
    custom_cod: false,
    custom_cod_value: 0,
  },
  insurance_fee: 0,
  cod_fee: 0,
  item_value: 0,
  shipping_cost: 0,
  warehouse_id: null,

  //reducer custom cod
  custom: {
    loading: false,
    success: false,
  },
  //reducer create
  create: {
    added: false,
    loading: false,
    success: false,
    message: "",
  },
  //request pickup / dropoff
  request: {
    ids: [],
    payment: {},
    details: [],
    success: false,
    loading: false,
    message: "",
  },
  //reducer get notification pacakge
  notification: {
    data: {
      new: 0,
      payment: 0,
      pickup: 0,
      shipped: 0,
      arrived: 0,
    },
    loading: false,
  },
  //reducer get multiple with ids
  multiple: {
    ids: [],
    data: [],
    success: false,
  },
  //reducer get process pacakge
  process: {
    ids: [],
    notification: [],
    data: [],
    payments: [],
    receipts: [],
    loading: false,
  },
  //reducer get history
  history: {
    data: [],
    loading: false,
  },
  //reducer remove package (update status to 9 for remove)
  remove: {
    ids: [],
    success: false,
    loading: false,
  },
  //reducer updated
  update: {
    ids: [],
    success: false,
    loading: false,
  },
  //reducer void
  void: {
    id: null,
    success: false,
    loading: false,
  },
  //reducer error data pacakge
  error: {
    sender: {},
    receiver: {},
    detail: {},
    cod_value: {
      error: false,
      msg: "",
    },
  },
  //reducer get Receivers
  receivers: {
    count: 0,
    data: [],
    loading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SENDER:
      return {
        ...state,
        sender: action.payload,
      };
    case types.SET_RECEIVER:
      return {
        ...state,
        receiver: action.payload,
      };
    case types.SET_WAREHOUSE_ID:
      return {
        ...state,
        warehouse_id: action.payload,
      };
    case types.SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case types.SET_ADD_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case types.SET_REMOVE_ITEM:
      return {
        ...state,
        items: state.items.filter(
          (item) => item.product_id !== action.payload.product_id
        ),
      };
    case types.SET_QTY_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item.product_id === action.payload.product_id
            ? {
                ...item,
                quantity: action.payload.quantity,
                weight: action.payload.weight,
                price: action.payload.price,
              }
            : item
        ),
      };
    case types.SET_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case types.SET_SHIPPING:
      return {
        ...state,
        shipping: action.payload,
        shipping_cost: action.payload.cost,
      };
    case types.RESET_SHIPPING:
      return {
        ...state,
        shipping: initialState.shipping,
        shipping_cost: initialState.shipping_cost,
      };
    case types.RESET_SHIPPING_SERVICE:
      return {
        ...state,
        shipping: {
          ...initialState.shipping,
          drop: state.shipping.drop,
          cod: state.shipping.cod,
        },
        shipping_cost: initialState.shipping_cost,
        insurance_fee: initialState.insurance_fee,
        cod_fee: initialState.cod_fee,
      };
    case types.SET_SHIPPING_INSURANCE:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          insurance: action.payload.checked,
          insurance_setting: {
            insurance_fee: action.payload.insurance_setting?.insurance_fee,
            insurance_add_cost:
              action.payload.insurance_setting?.insurance_add_cost,
          },
        },
        insurance_fee: action.payload.insurance_fee,
      };
    case types.RESET_SHIPPING_INSURANCE:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          insurance: initialState.shipping.insurance,
          insurance_setting: initialState.shipping.insurance_setting,
        },
        insurance_fee: initialState.insurance_fee,
      };
    case types.SET_SHIPPING_COD:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          cod: action.payload.checked,
          cod_setting: {
            cod_fee: action.payload.cod_setting?.cod_fee,
            minimum_cod_fee: action.payload.cod_setting?.minimum_cod_fee,
          },
        },
        cod_fee: action.payload.cod_fee,
      };
    case types.SET_CUSTOM_COD:
      return {
        ...state,
        shipping: {
          ...state.shipping,
          // custom_cod: action.payload,
          custom_cod_value: action.payload,
        },
      };
    case types.SET_ITEM_VALUE:
      return {
        ...state,
        item_value: action.payload,
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          ...action.payload,
        },
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case types.SET_ADDED:
      return {
        ...state,
        create: {
          ...state.create,
          added: action.payload,
        },
      };
    case types.CREATE_PACKAGE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          success: action.payload.success,
        },
      };
    case types.IS_LOADING_CREATE_PACKAGE:
      return {
        ...state,
        create: {
          ...state.create,
          loading: action.payload,
        },
      };
    case types.RESET_CREATE_PACKAGE:
      return {
        ...state,
        create: initialState.create,
      };

    case types.SET_IDS_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          ids: action.payload,
        },
      };
    case types.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: action.payload,
        },
      };
    case types.RESET_GET_NOTIFICATION:
      return {
        ...state,
        notification: initialState.notification,
      };

    case types.GET_PROCESS_SUCCESS:
      return {
        ...state,
        process: {
          ...state.process,
          ...action.payload,
        },
      };
    case types.IS_LOADING_GET_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          loading: action.payload,
        },
      };
    case types.RESET_GET_PROCESS:
      return {
        ...state,
        process: initialState.process,
      };

    case types.GET_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          loading: action.payload,
        },
      };
    case types.RESET_HISTORY:
      return {
        ...state,
        history: initialState.history,
      };

    case types.REMOVE_PACKAGE_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: {
            ...state.notification.data,
            new:
              action.payload.type === "new"
                ? state.notification.data.new - action.payload.ids.length
                : state.notification.data.new,
            payment:
              action.payload.type === "payment"
                ? state.notification.data.payment - action.payload.ids.length
                : state.notification.data.payment,
          },
        },
        process: {
          ...state.process,
          data: state.process.data.filter(
            (item) => !action.payload.ids.includes(item.id)
          ),
        },
        remove: {
          ...state.remove,
          ids: action.payload.ids,
          success: action.payload.success,
        },
      };
    case types.IS_LOADING_REMOVE_PACKAGE:
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: action.payload,
        },
      };
    case types.RESET_REMOVE_PACKAGE:
      return {
        ...state,
        remove: initialState.remove,
      };

    case types.CREATE_REQUEST_PICKUP_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: {
            ...state.notification.data,
            new: state.notification.data.new - action.payload.ids.length,
            payment:
              action.payload.type === "non-cod"
                ? state.notification.data.payment + action.payload.ids.length
                : state.notification.data.payment,
            pickup:
              action.payload.type === "cod"
                ? state.notification.data.pickup + action.payload.ids.length
                : state.notification.data.pickup,
          },
        },
        request: action.payload,
        process: {
          ...state.process,
          data: state.process.data.filter(
            (item) => !action.payload.ids.includes(item.id)
          ),
        },
      };
    case types.IS_LOADING_CREATE_REQUEST_PICKUP_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          loading: action.payload,
        },
      };
    case types.RESET_CREATE_REQUEST_PICKUP_SUCCESS:
      return {
        ...state,
        request: initialState.request,
      };
    case types.RESET_PACKAGE:
      return initialState;

    case types.UPDATE_UPLOADED_PROOF_PAYMENT_PACKAGE:
      return {
        ...state,
        process: {
          ...state.process,
          payments: state.process.payments.map((item) =>
            item.payment_id === action.payload.id
              ? {
                  ...item,
                  is_uploaded: true,
                  image_url: action.payload.image_url,
                  is_uploadedAt: action.payload.is_uploadedAt,
                }
              : item
          ),
        },
      };
    case types.UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          ids: action.payload.ids,
        },
        process: {
          ...state.process,
          receipts:
            action.payload.type === "printed"
              ? state.process.receipts.map((item) => ({
                  ...item,
                  receipt_printed: action.payload.ids.includes(item.id)
                    ? true
                    : item.receipt_printed,
                }))
              : state.process.receipts,
        },
      };
    case types.IS_LOADING_UPDATE_PACKAGE:
      return {
        ...state,
        update: {
          ...state.update,
          loading: action.payload,
        },
      };
    case types.RESET_UPDATE_PACKAGE:
      return {
        ...state,
        update: initialState.update,
      };

    case types.GET_MULTIPLE_IDS_SUCCESS:
      return {
        ...state,
        multiple: {
          ...state.multiple,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_MULTIPLE_IDS:
      return {
        ...state,
        multiple: {
          ...state.multiple,
          success: action.payload,
        },
      };
    case types.RESET_GET_MULTIPLE_IDS:
      return {
        ...state,
        multiple: initialState.multiple,
      };
    case types.SET_UPDATE_AWB_PROCESS_PACKAGE:
      return {
        ...state,
        process: {
          ...state.process,
          data: state.process.data.map((item) =>
            item.PackageShipping.number === action.payload.order_id
              ? {
                  ...item,
                  PackageShipping: {
                    ...item.PackageShipping,
                    awb: action.payload.awb,
                  },
                }
              : item
          ),
          receipts: state.process.receipt.map((item) =>
            item.PackageShipping.number === action.payload.order_id
              ? {
                  ...item,
                  PackageShipping: {
                    ...item.PackageShipping,
                    awb: action.payload.awb,
                  },
                }
              : item
          ),
        },
      };

    case types.VOID_PACKAGE_SUCCESS:
      return {
        ...state,
        void: {
          ...state.void,
          id: action.payload.id,
        },
        process: {
          ...state.process,
          receipts: state.process.receipt.filter(
            (item) => item.id !== action.payload.id
          ),
        },
        history: {
          ...state.history,
          data: state.history.data.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      };
    case types.IS_LOADING_VOID_PACKAGE:
      return {
        ...state,
        void: {
          ...state.void,
          loading: action.payload,
        },
      };
    case types.RESET_VOID_PACKAGE:
      return {
        ...state,
        void: initialState.void,
      };

    case types.GET_RECEIVERS_PACKAGE_SUCCESS:
      return {
        ...state,
        receivers: {
          ...state.receivers,
          count: action.payload.count,
          data: action.payload.data,
        },
      };
    case types.IS_LOADING_GET_RECEIVERS_PACKAGE:
      return {
        ...state,
        receivers: {
          ...state.receivers,
          loading: action.payload,
        },
      };
    case types.RESET_GET_RECEIVERS_PACKAGE:
      return {
        ...state,
        receivers: initialState.receivers,
      };
    case types.SET_CUSTOM_SUCCESS:
      return {
        ...state,
        custom: {
          ...state.custom,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const setSender = (payload) => ({
  type: types.SET_SENDER,
  payload,
});
export const setReceiver = (payload) => ({
  type: types.SET_RECEIVER,
  payload,
});
export const setWarehouseId = (payload) => ({
  type: types.SET_WAREHOUSE_ID,
  payload,
});
export const setItems = (payload) => ({
  type: types.SET_ITEMS,
  payload,
});
export const setAddItem = (payload) => ({
  type: types.SET_ADD_ITEM,
  payload,
});
export const setRemoveItem = (payload) => ({
  type: types.SET_REMOVE_ITEM,
  payload,
});
export const setQtyItem = (payload) => ({
  type: types.SET_QTY_ITEM,
  payload,
});
export const setDetail = (payload) => ({
  type: types.SET_DETAIL,
  payload,
});
export const setShipping = (payload) => ({
  type: types.SET_SHIPPING,
  payload,
});
export const resetShipping = () => ({
  type: types.RESET_SHIPPING,
});
export const resetShippingService = () => ({
  type: types.RESET_SHIPPING_SERVICE,
});

export const setInsurance = (payload) => ({
  type: types.SET_SHIPPING_INSURANCE,
  payload,
});
export const resetInsurance = () => ({
  type: types.RESET_SHIPPING_INSURANCE,
});
export const setCOD = (payload) => ({
  type: types.SET_SHIPPING_COD,
  payload,
});
export const setCustomCOD = (payload) => ({
  type: types.SET_CUSTOM_COD,
  payload,
});
export const setItemValue = (payload) => ({
  type: types.SET_ITEM_VALUE,
  payload,
});
export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});
export const resetError = (payload) => ({
  type: types.RESET_ERROR,
  payload,
});
export const setCustom = (payload) => ({
  type: types.SET_CUSTOM,
  payload,
});
export const setCustomSuccess = (payload) => ({
  type: types.SET_CUSTOM_SUCCESS,
  payload,
});

export const setAddedCreate = (payload) => ({
  type: types.SET_ADDED,
  payload,
});
export const createPackage = (payload) => ({
  type: types.CREATE_PACKAGE,
  payload,
});
export const createPackageSuccess = (payload) => ({
  type: types.CREATE_PACKAGE_SUCCESS,
  payload,
});
export const setIsLoadingCreatePackage = (payload) => ({
  type: types.IS_LOADING_CREATE_PACKAGE,
  payload,
});
export const resetCreatePackage = (payload) => ({
  type: types.RESET_CREATE_PACKAGE,
  payload,
});

export const getNotification = (payload) => ({
  type: types.GET_NOTIFICATION,
  payload,
});
export const getNotificationSuccess = (payload) => ({
  type: types.GET_NOTIFICATION_SUCCESS,
  payload,
});
export const setIsLoadingGetNotification = (payload) => ({
  type: types.IS_LOADING_GET_NOTIFICATION,
  payload,
});
export const resetGetNotification = (payload) => ({
  type: types.RESET_GET_NOTIFICATION,
  payload,
});

export const setIdsProcessNew = (payload) => ({
  type: types.SET_IDS_PROCESS,
  payload,
});
export const getProcessNew = (payload) => ({
  type: types.GET_PROCESS_NEW,
  payload,
});
export const getProcessPayment = (payload) => ({
  type: types.GET_PROCESS_PAYMENT,
  payload,
});
export const getProcessReceipt = (payload) => ({
  type: types.GET_PROCESS_RECEIPT,
  payload,
});
export const getProcessSuccess = (payload) => ({
  type: types.GET_PROCESS_SUCCESS,
  payload,
});
export const setIsLoadingGetProcess = (payload) => ({
  type: types.IS_LOADING_GET_PROCESS,
  payload,
});
export const resetGetProcess = (payload) => ({
  type: types.RESET_GET_PROCESS,
  payload,
});

export const getHistoryWaitingPickup = (payload) => ({
  type: types.GET_HISTORY_WAITING_PICKUP,
  payload,
});
export const getHistoryShipped = (payload) => ({
  type: types.GET_HISTORY_SHIPPED,
  payload,
});
export const getHistoryArrived = (payload) => ({
  type: types.GET_HISTORY_ARRIVED,
  payload,
});
export const getHistoryDone = (payload) => ({
  type: types.GET_HISTORY_DONE,
  payload,
});
export const getHistoryReturn = (payload) => ({
  type: types.GET_HISTORY_RETURN,
  payload,
});
export const getHistoryCanceled = (payload) => ({
  type: types.GET_HISTORY_CANCELED,
  payload,
});
export const getHistorySuccess = (payload) => ({
  type: types.GET_HISTORY_SUCCESS,
  payload,
});
export const setIsLoadingGetHistorySuccess = (payload) => ({
  type: types.IS_LOADING_GET_HISTORY_SUCCESS,
  payload,
});
export const resetHistory = (payload) => ({
  type: types.RESET_HISTORY,
  payload,
});

export const removeSinglePackage = (payload) => ({
  type: types.REMOVE_SINGLE_PACKAGE,
  payload,
});
export const removeMultiplePackage = (payload) => ({
  type: types.REMOVE_MULTIPLE_PACKAGE,
  payload,
});
export const removePackageSuccess = (payload) => ({
  type: types.REMOVE_PACKAGE_SUCCESS,
  payload,
});
export const setIsLoadingRemovePackage = (payload) => ({
  type: types.IS_LOADING_REMOVE_PACKAGE,
  payload,
});
export const resetRemovePackage = (payload) => ({
  type: types.RESET_REMOVE_PACKAGE,
  payload,
});

export const requestPickupCodPackage = (payload) => ({
  type: types.CREATE_REQUEST_PICKUP_COD,
  payload,
});
export const requestPickupNonCodPackage = (payload) => ({
  type: types.CREATE_REQUEST_PICKUP_NONCOD,
  payload,
});
export const requestPickupSuccess = (payload) => ({
  type: types.CREATE_REQUEST_PICKUP_SUCCESS,
  payload,
});
export const setIsLoadingRequestPickup = (payload) => ({
  type: types.IS_LOADING_CREATE_REQUEST_PICKUP_SUCCESS,
  payload,
});
export const resetRequestPickup = (payload) => ({
  type: types.RESET_CREATE_REQUEST_PICKUP_SUCCESS,
  payload,
});

export const updateUploadedProofPaymentPackage = (payload) => ({
  type: types.UPDATE_UPLOADED_PROOF_PAYMENT_PACKAGE,
  payload,
});
export const updateReceiptPrintedPackage = (payload) => ({
  type: types.UPDATE_RECEIPT_PRINTED_MULTIPLE_PACKAGE,
  payload,
});
export const updatePackageSuccess = (payload) => ({
  type: types.UPDATE_PACKAGE_SUCCESS,
  payload,
});
export const setIsLoadingUpdatePackage = (payload) => ({
  type: types.IS_LOADING_UPDATE_PACKAGE,
  payload,
});
export const resetUpdatePackage = (payload) => ({
  type: types.RESET_UPDATE_PACKAGE,
  payload,
});

export const setMultipleIds = (payload) => ({
  type: types.SET_MULTIPLE_IDS,
  payload,
});
export const getMultiplePackages = (payload) => ({
  type: types.GET_MULTIPLE_IDS,
  payload,
});
export const getMultiplePackagesSuccess = (payload) => ({
  type: types.GET_MULTIPLE_IDS_SUCCESS,
  payload,
});
export const setIsLoadinggetMultiplePackages = (payload) => ({
  type: types.IS_LOADING_GET_MULTIPLE_IDS,
  payload,
});
export const resetGetMultiplePackages = (payload) => ({
  type: types.RESET_GET_MULTIPLE_IDS,
  payload,
});

export const setUpdateAWBProcessPackage = (payload) => ({
  type: types.SET_UPDATE_AWB_PROCESS_PACKAGE,
  payload,
});

export const voidPackage = (payload) => ({
  type: types.VOID_PACKAGE,
  payload,
});
export const voidPackageSuccess = (payload) => ({
  type: types.VOID_PACKAGE_SUCCESS,
  payload,
});
export const setIsLoadinggetVoidPackage = (payload) => ({
  type: types.IS_LOADING_VOID_PACKAGE,
  payload,
});
export const resetVoidPackages = (payload) => ({
  type: types.RESET_VOID_PACKAGE,
  payload,
});

export const resetPackage = (payload) => ({
  type: types.RESET_PACKAGE,
});

export const getReceivers = (payload) => ({
  type: types.GET_RECEIVERS_PACKAGE,
  payload,
});
export const getReceiversSuccess = (payload) => ({
  type: types.GET_RECEIVERS_PACKAGE_SUCCESS,
  payload,
});
export const setIsLoadingGetReceivers = (payload) => ({
  type: types.IS_LOADING_GET_RECEIVERS_PACKAGE,
  payload,
});
export const resetGetReceivers = (payload) => ({
  type: types.RESET_GET_RECEIVERS_PACKAGE,
  payload,
});
